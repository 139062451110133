<!-- eslint-disable vue/no-v-html -->
<template>
	<v-flex
		v-bind="$attrs"
		color="primary"
		:class="{
			'drop-zone': true,
			'pointer': !readonly
		}"
		align-center
		d-flex
		justify-center
		style="max-width: 100%"
		text-xs-center
		@click="onClick"
		@dragleave.prevent="onDragLeave"
		@dragover.prevent="onDragOver"
		@drop="onDrop"
	>
		<img v-if="displayedImage" :src="displayedImage" alt="upload preview" style="height: auto; max-height: 100%; max-width: 100%; object-fit: contain" />
		<v-flex v-else v-html="labelImage" />
	</v-flex>
</template>

<script>
/**
 * @displayName ImageInput
 */
export default {
	name: 'ImageInput',
	props: {
		accept: {
			default: undefined,
			required: false,
			type: String
		},
		activeColor: {
			default: 'primary',
			required: false,
			type: String
		},
		readonly: {
			default: false,
			required: false,
			type: Boolean
		},
		required: {
			default: false,
			type: Boolean
		},
		label: {
			default: '',
			required: false,
			type: String
		},
		value: {
			required: false,
			type: [File, String],
			default: undefined
		}
	},
	data: function () {
		return {
			displayedImage: null
		}
	},
	computed: {
		labelImage: function () {
			return this.required ? this.label + '*' : this.label
		}
	},
	watch: {
		value: {
			handler: 'computeDisplayedImage',
			immediate: true
		}
	},
	methods: {
		computeDisplayedImage: function () {
			new Promise(resolve => {
				if (typeof this.value === 'string') {
					this.displayedImage = this.value
				} else if (this.value instanceof File) {
					this.displayedImage = URL.createObjectURL(this.value)
				} else {
					this.displayedImage = null
				}
				resolve()
			})
		},
		/**
		 * @param {File} file
		 */
		emitInput: function (file) {
			/**
			 * @event success
			 *
			 * @property {File} file
			 */
			this.$emit('input', file)
		},
		onClick: function () {
			if (this.readonly) {
				return
			}
			const fileInput = document.createElement('input')
			fileInput.accept = this.accept ?? 'image/*'
			fileInput.multiple = false
			fileInput.type = 'file'
			fileInput.addEventListener(
				'input',
				function (e) {
					e.preventDefault()
					const file = e.target.files[0]
					this.emitInput(file)
					fileInput.remove()
				}.bind(this)
			)
			fileInput.click()
		},
		onDragOver: function () {
			this.markContainerAsActive()
		},
		onDragLeave: function () {
			this.markContainerAsInactive()
		},
		onDrop: async function (event) {
			event.stopPropagation()
			event.preventDefault()

			if (this.readonly) {
				return
			}

			this.markContainerAsInactive()

			const dataTransfer = event.dataTransfer

			let newFiles = []
			if (dataTransfer.items) {
				for (let i = 0; i < dataTransfer.items.length; i++) {
					const entry = dataTransfer.items[i].webkitGetAsEntry()
					if (entry.isDirectory) {
						let files = await this.getFilesOfDirectory(entry)
						newFiles = newFiles.concat(files)
					} else {
						newFiles.push(dataTransfer.items[i].getAsFile())
					}
				}
			} else if (dataTransfer.files) {
				dataTransfer.files.forEach(file => {
					newFiles.push(file)
				})
			} else {
				throw new Error("Browser doesn't support OS file Drag n' Drop")
			}
			if (this.accept) {
				newFiles = newFiles.filter(file => {
					return file.type && this.accept.includes(file.type)
				})
			}
			if (newFiles.length > 0) {
				this.emitInput(newFiles[0])
			}
		},
		markContainerAsActive: function () {
			return new Promise(resolve => {
				this.$el.classList.add('drop-zone-active')
				if (this.activeColor[0] === '#') {
					this.$el.style.borderColor = this.activeColor
				} else {
					this.$el.style.borderColor = `var(--v-${this.activeColor}-base)`
				}
				resolve()
			})
		},
		markContainerAsInactive: function () {
			return new Promise(resolve => {
				this.$el.classList.remove('drop-zone-active')
				this.$el.style.borderColor = null
				resolve()
			})
		}
	}
}
</script>

<style>
.drop-zone {
	border: 0.15em dashed;
	border-color : var(--v-softGrey-darken4);
	transition: 0.25s;
}

.drop-zone-active {
	border: 0.25em dashed;
	border-color: var(--v-primary-base);
}
</style>
